import React, { useEffect } from 'react';

const DynamicFavicon = ({ icon }) => {
  useEffect(() => {
    const linkElement = document.querySelector("link[rel*='icon']");
    linkElement.href = `${process.env.PUBLIC_URL}/favicon/${icon}.ico`;
  }, [icon]);

  return null;
};

export default DynamicFavicon;