import * as React from 'react';
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useUser from "../../user/use";
import axios from "axios";

import {topTitle, getDiv} from "../SchoolsList/SchoolList3";

import {Typography, Button, Box} from "@mui/material";

import "./Regency.css";

const Item = (props) => {
  return (
    <Box sx={{
      width: '50px',
      backgroundColor: `${props.backgroundColor}`,
      color: "white",
      marginLeft: "2px",
      marginRight: "2px",
      // margin: "auto",
      textAlign: "center",
      fontSize: "10px",
      paddingTop: "4px",
      paddingRight: "2px",
      paddingBottom: "4px",
      paddingLeft: "2px",
    }}>
      {props.children}
    </Box>
  );
};

export function Regency(props) {
  const [listSchools, setListSchools] = useState(null);
  const [listSchoolStages, setListSchoolStages] = useState(null);
  const history = useHistory();

  const {regencyId} = useParams();

  const [update, setUpdate] = useState(false);

  const appType = process.env.REACT_APP_APPTYPE;

  const {
    state: {userId, role, token},
  } = useUser();

  let titleInstrument = "";
  let linkString = "";

  titleInstrument = "Province";
  linkString = "schoolcategories2";

  useEffect(() => {
      let typeEMAudit = "2";
      if (appType === "LPLRA") {
          typeEMAudit = "3";
      }

    // TODO: get all schools --> get authorize schools
    // const data = await axios.get(queryString, {params: {userId: userId, role: role}});
    const url = process.env.REACT_APP_SERVER + "/regencySchool";
    axios.get(url, {params: {regencyId: regencyId, typeemaudit: typeEMAudit}})
      .then(
        response => {
          // console.log(response.data.data);
          setListSchools(response.data.data);
          // how if add info for each list schools here?
          // console.log(response.data.data);
        }
      )
      .catch(err => {
        console.log(err);
      });
    setUpdate(false)
  }, [role, regencyId, update]);

  return (
    <div className={"regency"}>
      { (appType==="SRA" || (appType==="SRAMandiri") ) && topTitle("Satuan Pendidikan")}
      <Box sx={{width: '950px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Item>
          <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                  onClick={() => history.goBack()}>Kembali</Button>
        </Item>
      </Box>
      {getDiv(userId, role, listSchools, linkString, appType, setUpdate)}
      <Box sx={{width: '950px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Item>
          <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                  onClick={() => history.goBack()}>Kembali</Button>
        </Item>
      </Box>
    </div>
  );
};