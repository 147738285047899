import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {Box, CircularProgress, Typography} from "@mui/material";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  return date.toLocaleDateString('id-ID', options);
}

function PostList(props) {
  const [content, setContent] = useState("");

  useEffect(() => {
    const query = process.env.REACT_APP_CONTENT + "/posts?categories=6&orderby=date&order=desc";
    axios.get(query)
      .then(response => {
        setContent(response.data);
        // console.log(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Box marginTop={4}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
          </Grid>
          <Grid item={true} xs={2}> </Grid>
          <Grid item={true} xs={8}>
            <Typography variant="h4" component="h1" gutterBottom={true}>
              Daftar Notifikasi
            </Typography>
          </Grid>
          <Grid item={true} xs={2}> </Grid>

          <Grid item={true} xs={2}> </Grid>
          <Grid item={true} xs={8}>

            {!content && <CircularProgress/>}

              {content && content.map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`PostDetail/${item.id}`} style={{textDecoration: 'none'}}>

                      <Box display="flex">
                        <Box marginLeft="8px" marginRight={"8px"}>
                          <Typography variant="body1" component="p" gutterBottom={true}>
                          {formatDate(item.date)}
                          </Typography>
                        </Box>
                        <Box marginLeft="8px" marginRight={"8px"}>
                          <Typography variant="body1" component="p" gutterBottom={true}>
                          {item.title.rendered}
                          </Typography>
                        </Box>
                      </Box>
                      {/*</Typography>*/}
                    </Link>
                    {/*<div dangerouslySetInnerHTML={{__html: item.title.rendered}}/>*/}
                  </div>
                );
              })}
            {/*</Typography>*/}
          </Grid>
          <Grid item={true} xs={2}> </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default PostList;