import * as React from 'react';
import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import useUser from "../../user/use";
import axios from "axios";
import {Box, Button, Paper, Typography} from "@mui/material";
import {topTitle} from "../SchoolsList/SchoolList3";

import "./Province.css";

export function Province(props) {
  const [listRegencies, setListRegencies] = useState(null);
  const [listSchoolStages, setListSchoolStages] = useState(null);
  const history = useHistory();
  const [update, setUpdate] = useState(false);

  const appType = process.env.REACT_APP_APPTYPE;

  const {
    state: {userId, role, token},
  } = useUser();

  let titleInstrument = "";
  let linkString = "regency";

  useEffect(() => {
    // TODO: get all schools --> get authorize schools
    // const data = await axios.get(queryString, {params: {userId: userId, role: role}});
    const url = process.env.REACT_APP_SERVER + "/provinceRegencySchool";
    axios.get(url, {params: {userId: userId, role: role}})
      .then(
        response => {
          // console.log(response.data.data);
          setListRegencies(response.data.data);
          // console.log(response.data.data[0]);
          // how if add info for each list schools here?
          // console.log(response.data.data);
        }
      )
      .catch(err => {
        console.log(err);
      });
  }, [role, userId, update]);

  String.prototype.toProperCase = function () {
    console.log(this);
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className={"province"}>
      {topTitle("Beranda")}
      {/*<h1>Province</h1>*/}
      {
        listRegencies && listRegencies.map((regency, index) =>
          <div key={index}>
            <Box sx={{width: "500px"}}>
              <Paper elevation={0}
                   sx={{
                     padding: "10px",
                     marginLeft: "15px",
                     marginRight: "15px",
                     marginTop: "10px",
                     marginBottom: "10px"
                   }}>
              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{width: "300px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
                  <Typography variant="h7" sx={{fontSize: '18px'}}>{regency.regency_name.toProperCase()}</Typography>
                </Box>
                <Box sx={{width: "200px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
                  <Typography variant="h7" sx={{fontSize: '18px'}}>[{regency.total_schools}]</Typography>
                </Box>
                <Box>
                  <Link to={`/${linkString}/${regency.regency_id.toString()}`} style={{textDecoration: 'none'}}>
                    <Button variant="contained" size="small"
                            sx={{
                              marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                              borderRadius: "0px", boxShadow: "none"
                            }}>Lihat</Button>
                  </Link>
                </Box>
              </Box>
            </Paper>
            </Box>
          </div>
        )
      }
    </div>
  );
};


