import * as React from 'react';
import {useEffect, useState} from "react";
import axios from "axios";
import {Box, CircularProgress, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

function Tutorial(props) {
  const [content, setContent] = useState("");

  useEffect(() => {
    const query = process.env.REACT_APP_CONTENT + "/pages/164";
    axios.get(query)
      .then(response => {
        setContent(response.data);
        // console.log(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Box marginTop={4}>
        {!content &&
          <>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}> </Grid>
              <Grid item={true} xs={2}> </Grid>
              <Grid item={true} xs={8}>
                <CircularProgress/>
              </Grid>
              <Grid item={true} xs={2}> </Grid>
            </Grid>
          </>
        }
        {content &&
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
            </Grid>

            <Grid item={true} xs={2}> </Grid>
            <Grid item={true} xs={8}>
              <Typography variant="h4" component="h1" mt="0px" mb="0px" pt="0px" pb="0px" gutterBottom={true}>
                <div dangerouslySetInnerHTML={{__html: content.title.rendered}}/>
              </Typography>
            </Grid>
            <Grid item={true} xs={2}> </Grid>

            <Grid item={true} xs={2}> </Grid>
            <Grid item={true} xs={8}>
              {/*<Typography variant="body1" component="p"  gutterBottom={true}>*/}
              <div dangerouslySetInnerHTML={{__html: content.content.rendered}}/>
              {/*</Typography>*/}
            </Grid>
            <Grid item={true} xs={2}> </Grid>
          </Grid>
        }
      </Box>
    </div>
  );
};

export default Tutorial;