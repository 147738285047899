import React, {useEffect} from 'react';
import queryString from 'query-string'
import axios from 'axios';
import {useHistory, useLocation} from 'react-router-dom';
import ForgetNewPassword from "./ForgetNewPassword";


export function ResetConfirmation(props) {
  // https://ui.dev/react-router-v5-query-strings
  // https://www.npmjs.com/package/query-string
  const history = useHistory();
  const [code2, setCode2] = React.useState("");

  const url = process.env.REACT_APP_SERVER;
  const { search } = useLocation()
  const values = queryString.parse(search)
  // console.log(values.code) // "code ~ hashcode

  const [message, setMessage] = React.useState(null);

  // var message = "";

  useEffect(() => {
    if (values.code) {
      const data = {
        code: values.code
      }
      axios.post(url+"/confirmforgetpassword?code=", data)
        .then(response => {
          // console.log(response);
          if (response.data.data === "not found") {
            // console.log("not found");
            setMessage("kode konfirmasi tidak valid, atau sudah pernah dikonfirmasi");
            // message = "kode konfirmasi tidak valid, atau sudah pernah dikonfirmasi";
          } else {
            // console.log("found");
            setMessage("Account anda sudah terkonfirmasi, silahkan login ulang.");
            // console.log(response.data.message);
            // console.log(response.data)
            setCode2(response.data.code2);
            // message = "Account anda sudah terkonfirmasi, silahkan login ulang.";
            // go to ForgetNewPassword
            // history.push("/forgetnewpassword?code=",+values.code);

            // Navigasi ke komponen B dan mengirimkan data
            history.push({
              pathname: '/forgetnewpassword',
              state: { code2: response.data.code2 }
            });

          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  },[url, values.code]);

  return (
    <div>
      {message}
      {/*<ForgetNewPassword />*/}
      {/*{code2 && <div>*/}
      {/*  <h1>code2: {code2}</h1>*/}
      {/*</div>}*/}
    </div>
  );
}

export default ResetConfirmation;