import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from "react-hook-form";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" "}
      <Link color="inherit" href="#">
        {process.env.REACT_APP_TITLE} - KemenPPPA
      </Link>
      {" - "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgetNewPassword(props) {
  const appType = process.env.REACT_APP_APPTYPE;
  const url = process.env.REACT_APP_SERVER;
  const location = useLocation();
  const history = useHistory();
  const code2 = location.state?.code2;
  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  // console.log(code2);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  // };

  const handleSubmit3 = data => {
    // console.log("helo");
    // console.log(code2);
    if ((code2 === undefined) || (code2 === null)) {
      console.log("code2 undefined");
      return;
    }

    const data2 = {
      code2: code2,
      newpassword: data.newpassword
    }
    // console.log(data2);
    axios.post(url + "/changepassword", data2)
      .then(res => {
        // console.log(res.data.message);
        if (res.data.message === 'Password berhasil diubah') {
          alert("Password berhasil diubah. Silahkan login kembali.");
          history.push('/signin');
        } else {
          alert("Password gagal diubah. Silahkan coba lagi.");
        }
      })
      .catch(err => {
        console.log(err);
      })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {((appType === "SRA") || (appType === 'SRAMandiri')) &&
            <img src="/logo_sra_baru-nobg-120.png" alt="logo"/>
          }
          {((appType !== "SRA") && (appType !== 'SRAMandiri')) &&
            <img src="/76.png" alt="logo"/>
          }
          {/*<Avatar sx={{m: 1, bgcolor: "secondary.main"}}>*/}
          <br/>
          <Typography component="h1" variant="h5">
            Lupa Password
          </Typography>
          <form
            noValidate
            onSubmit={handleSubmit(handleSubmit3)}
          >
          <Box sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password Baru"
                type="password"
                id="password"
                autoComplete="password"
                {...register("newpassword", {
                  required: "Password harus diisi",
                  pattern: /^[a-zA-Z0-9!@#$%^&*]{6,16}$/
                })}
              />
              {errors.password ? "Password minimum 6 karakter, harus diisi, tidak boleh ada spasi" : ""}
              {/*  https://stackoverflow.com/questions/12090077/javascript-regular-expression-password-validation-having-special-characters */}
            </Grid>
            {/*<TextField*/}
            {/*  margin="normal"*/}
            {/*  required*/}
            {/*  fullWidth*/}
            {/*  id="password2"*/}
            {/*  label="Ulangi Password Baru"*/}
            {/*  name="password2"*/}
            {/*  autoComplete="password2"*/}
            {/*  // autoFocus*/}
            {/*/>*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Ganti Password
            </Button>
            {/*<Grid container justifyContent="flex-end">*/}
            {/*  <Grid item>*/}
            {/*    <Link href="/signin" variant="body2">*/}
            {/*      Apakah anda sudah memiliki akun? Sign in*/}
            {/*    </Link>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Box>
          </form>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}