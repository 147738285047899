import React, {useEffect} from 'react';
import queryString from 'query-string'
import axios from 'axios';
import { useLocation } from 'react-router-dom'

const Confirmation = () => {

  // https://ui.dev/react-router-v5-query-strings
  // https://www.npmjs.com/package/query-string

  const url = process.env.REACT_APP_SERVER;
  const { search } = useLocation()
  const values = queryString.parse(search)
  console.log(values.code) // "code ~ hashcode

  const [message, setMessage] = React.useState("");

  // var message = "";

  useEffect(() => {
    if (values.code) {
      axios.get(url+"/confirmation?code="+values.code)
        .then(response => {
          console.log(response);
          if (response.data.data === "not found") {
            // console.log("not found");
            setMessage("kode konfirmasi tidak valid, atau sudah pernah dikonfirmasi");
            // message = "kode konfirmasi tidak valid, atau sudah pernah dikonfirmasi";
          } else {
            // console.log("found");
            setMessage("Account anda sudah terkonfirmasi, silahkan login ulang.");
            // message = "Account anda sudah terkonfirmasi, silahkan login ulang.";
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  },[]);

  return (
    <div>
      {message}
    </div>
  );
};

export default Confirmation;