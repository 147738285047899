import * as React from 'react';
import {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import useUser from "../../user/use";
import axios from "axios";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


export function SchoolList3(props) {
  return (
    <div>

    </div>
  );
};const BigItem = (props) => {
  return (
    <Box sx={{
      width: '70px',
      backgroundColor: `${props.backgroundColor}`,
      color: "white",
      marginLeft: "2px",
      marginRight: "2px",
      // margin: "auto",
      textAlign: "center",
      fontSize: "14px",
      paddingTop: "4px",
      paddingRight: "2px",
      paddingBottom: "4px",
      paddingLeft: "2px",
    }}>
      {props.children}
    </Box>
  );
};
const Item = (props) => {
  return (
    <Box sx={{
      width: '50px',
      backgroundColor: `${props.backgroundColor}`,
      color: "white",
      marginLeft: "2px",
      marginRight: "2px",
      // margin: "auto",
      textAlign: "center",
      fontSize: "10px",
      paddingTop: "4px",
      paddingRight: "2px",
      paddingBottom: "4px",
      paddingLeft: "2px",
    }}>
      {props.children}
    </Box>
  );
};
const MyitemSRAMandiri = ({
                            no = "",
                            schoolName = "",
                            schoolType = "",
                            schoolStage = "",
                            regencyName = "",
                            provinceName = "",
                            em1 = "-",
                            audit1 = "-",
                            audit2 = "-",
                            em2 = "-",
                            audit3 = "-",
                            max = "-",
                            link,
                            sEm1 = "-",
                            sAudit1 = "-",
                            sAudit2 = "-",
                            sEm2 = "-",
                            sAudit3 = "-",
                            sMax = "-",
                            qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                            showAudit1 = true,
                            appType = "SRA",
                            setUpdate
                            // setUpdate, update
                          }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const {
    state: {userId, role, token},
  } = useUser();

  const [open, setOpen] = useState(false);

  // https://mui.com/material-ui/react-menu/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    console.log('clicked');
    console.log(no);
    console.log(typeof no);
  };

  const handleChangeSubmit = () => {
    console.log('changed submit');
    console.log(no);
    console.log(typeof no);

    const data = {
      schoolid: no,
      userid: userId,
      role: role,
      action: "submit"
    };

    axios.patch(process.env.REACT_APP_SERVER + "/submit", data)
      // axios.post(process.env.REACT_APP_SERVER + "/submit", data, config)
      //  axios.get(process.env.REACT_APP_SERVER + "/submit", {params: {secret_token: token, role:role, data }})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    // setUpdate(!update);
    setOpen(false);
    setUpdate(true);
  };

  const handleChangeUnsubmit = () => {
    console.log('changed unsubmit');
    console.log(no);
    console.log(typeof no);

    const data = {
      schoolid: no,
      userid: userId,
      role: role,
      action: "unsubmit"
    };

    axios.patch(process.env.REACT_APP_SERVER + "/submit", data)
      // axios.post(process.env.REACT_APP_SERVER + "/submit", data, config)
      //  axios.get(process.env.REACT_APP_SERVER + "/submit", {params: {secret_token: token, role:role, data }})
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });

    // setUpdate(!update);
    setOpen(false);
    setUpdate(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>
          {no}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolName}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolType}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {provinceName.toProperCase()}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {regencyName.toProperCase()}
        </Box>
        {(schoolStage == 10) &&
          <Box sx={{width: "50px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
            <Tooltip title="Submitted">
              <CheckBoxIcon/>
            </Tooltip>
          </Box>
        }
        {(schoolStage != 10) &&
          <Box sx={{width: "50px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
            <Tooltip title="Belum di-submitted">
              <CheckBoxOutlineBlankIcon/>
            </Tooltip>
          </Box>
        }
        <Box sx={{width: "50px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          <Tooltip title="Belum dicheck">
            <RadioButtonUncheckedIcon/>
          </Tooltip>
        </Box>
        <Box sx={{marginLeft: "15px", marginRight: "15px"}}>
          <MenuIcon id="basic-button"
                    aria-controls={open2 ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? 'true' : undefined}
                    onClick={handleClick2}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <BigItem backgroundColor="#006638">EM1: {em1}</BigItem>
            {/*{showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&*/}
            {/*  <Item backgroundColor="#006638">AU1: {audit1}</Item>*/}
            {/*}*/}
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">AUDIT: {audit2}</Item>
            }
            {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
            {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
            <BigItem backgroundColor="#006638">Maks: {max}</BigItem>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
          {/*  /!*{showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&*!/*/}
          {/*  /!*  <Item backgroundColor="#006638">Std: {sAudit1}</Item>*!/*/}
          {/*  /!*}*!/*/}
          {/*  {(appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
          {/*  }*/}
          {/*  /!*<Item backgroundColor="#006638">Std: {sEm2}</Item>*!/*/}
          {/*  /!*<Item backgroundColor="#006638">Std: {sAudit3}</Item>*!/*/}
          {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
          {/*</Box>*/}
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} position={{top: '50%', left: '50%'}}>
        <DialogTitle>Apakah anda yakin ingin melanjutkan?</DialogTitle>
        <DialogContent>
          <p>
            Jika anda memilih <strong>Submitted</strong>, maka satuan pendidikan tersebut akan berubah menjadi sudah
            ter"submit",
            sehingga satuan pendidikan tersebut tidak dapat mengisi/mengubah kembali. Jika anda
            memilih <strong>Unsubmitted</strong>, maka
            satuan pendidikan tersebut akan berubah menjadi belum ter"submit", sehingga satuan pendidikan tersebut dapat
            mengisi/mengubah kembali.
            Untuk batal, silahkan klik tombol <strong>Batal</strong>.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeSubmit} color="primary">
            Submitted
          </Button>
          <Button onClick={handleChangeUnsubmit} color="primary">
            Unsubmitted
          </Button>
          <Button onClick={handleClose} color="primary">
            Batal
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose2}>Profil</MenuItem>
        <MenuItem onClick={handleClose2}>Check/Uncheck</MenuItem>
        <MenuItem onClick={() => setOpen(true)}>Submit/Unsubmit</MenuItem>
        <MenuItem onClick={handleClose2}>Hapus</MenuItem>
      </Menu>
    </Paper>
  );
};
const MyitemSRA = ({
                     no = "",
                     schoolName = "",
                     regencyName = "",
                     provinceName = "",
                     em1 = "-",
                     audit1 = "-",
                     audit2 = "-",
                     em2 = "-",
                     audit3 = "-",
                     max = "-",
                     link,
                     sEm1 = "-",
                     sAudit1 = "-",
                     sAudit2 = "-",
                     sEm2 = "-",
                     sAudit3 = "-",
                     sMax = "-",
                     qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                     showAudit1 = true,
                     appType = "SRA"
                   }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>
          {no}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolName}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {provinceName.toProperCase()}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {regencyName.toProperCase()}
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">EM1: {em1}</Item>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">AUDIT: {audit2}</Item>
            }
            <Item backgroundColor="#006638">EM2: {em2}</Item>
            <Item backgroundColor="#006638">AUF: {audit3}</Item>
            <Item backgroundColor="#006638">Maks: {max}</Item>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">Std: {sEm1}</Item>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">Std: {sAudit1}</Item>
            }
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">Std: {sAudit2}</Item>
            }
            <Item backgroundColor="#006638">Std: {sEm2}</Item>
            <Item backgroundColor="#006638">Std: {sAudit3}</Item>
            <Item backgroundColor="#006638">Std: {sMax}</Item>
          </Box>
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
const MyitemPISA = ({
                      no = "",
                      schoolName = "",
                      regencyName = "",
                      provinceName = "",
                      em1 = "-",
                      audit1 = "-",
                      audit2 = "-",
                      em2 = "-",
                      audit3 = "-",
                      max = "-",
                      link,
                      sEm1 = "-",
                      sAudit1 = "-",
                      sAudit2 = "-",
                      sEm2 = "-",
                      sAudit3 = "-",
                      sMax = "-",
                      qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                      showAudit1 = true,
                      appType = "SRA",
                      stage = 0,
                      role = ""
                    }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
        {no}
        {/*</Box>*/}
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolName}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {provinceName.toProperCase()}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {regencyName.toProperCase()}
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '2px',
            marginBottom: '2px',
            width: '350px'
          }}>
            {/*<Item backgroundColor="#006638">EM1: {em1}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM1:{em1}</Typography>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AU1:{audit1}</Typography>
            }
            {(appType === "PISA") &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUDIT:{audit2}</Typography>
            }
            {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM2:{em2}</Typography>
            {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
            {
              ((stage !== 6) && (role == "operator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF:-</Typography>
            }
            {
              ((stage === 6) && (role == "operator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF: {audit3}</Typography>
            }
            {
              ((role == "auditor") || (role == "administrator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF: {audit3}</Typography>
            }

            {/*<Item backgroundColor="#006638">Maks: {max}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>Maks:{max}</Typography>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
          {/*  {showAudit1 && (appType === "SRA" || appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {(appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
          {/*  }*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
          {/*</Box>*/}
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
const Myitem = ({
                  no = "",
                  schoolName = "",
                  schoolType = "",
                  schoolStage = "",
                  regencyName = "",
                  provinceName = "",
                  em1 = "-",
                  audit1 = "-",
                  audit2 = "-",
                  em2 = "-",
                  audit3 = "-",
                  max = "-",
                  link,
                  sEm1 = "-",
                  sAudit1 = "-",
                  sAudit2 = "-",
                  sEm2 = "-",
                  sAudit3 = "-",
                  sMax = "-",
                  qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                  showAudit1 = true,
                  appType = "SRA",
                  stage = 0,
                  role = "",
                  setUpdate
                }) => {
  return (
    <div>
      {(appType === "SRA" || appType === "LPKRA" || appType === "LPLRA" || appType === "PRAP") &&
        <MyitemSRA no={no} schoolName={schoolName} regencyName={regencyName} provinceName={provinceName}
                   em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                   max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                   sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                   qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1}>
        </MyitemSRA>
      }
      {(appType === "PISA") &&
        <MyitemPISA no={no} schoolName={schoolName} regencyName={regencyName} provinceName={provinceName}
                    em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                    max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                    sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                    qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1} stage={stage} role={role}>
        </MyitemPISA>
      }
      {(appType === "SRAMandiri" || appType === "PRAPMandiri") &&
        <MyitemSRAMandiri no={no} schoolName={schoolName} schoolType={schoolType} schoolStage={schoolStage}
                          regencyName={regencyName} provinceName={provinceName}
                          em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                          max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                          sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                          qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1} setUpdate={setUpdate}>
        </MyitemSRAMandiri>
      }
    </div>
  );
};

export function topTitle(subTitle) {
  return <>
    <Typography variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
    <h2>{subTitle}</h2>
  </>;
}

export function getDiv(userId, role, listSchools, linkString, appType, setUpdate) {
  // console.log(typeof setUpdate)
  return (
    <div className={"schoolslist"}>
      {(process.env.REACT_APP_DEV == 1) &&
        <div>
          schoolid:- - stage:- - userid:{userId} - role:{role}
        </div>
      }
      {listSchools && listSchools.length === 0 &&
        <div>
          <h3>Belum ada sekolah yang terdaftar</h3>
        </div>
      }
      {listSchools && listSchools.length > 0 &&
        listSchools.map(school =>
          <div key={school.school_id}>
            <Myitem no={school.school_id} schoolName={school.school_name} schoolType={school.school_type}
                    schoolStage={school.stage}
                    em1={school.total_em_1_score} sEm1={school.em_1_meet_standard}
                    audit1={school.total_audit_1_score} sAudit1={school.audit_1_meet_standard}
                    audit2={school.total_audit_2_score} sAudit2={school.audit_2_meet_standard}
                    em2={school.total_em_2_score} sEm2={school.em_2_meet_standard}
                    audit3={school.total_audit_3_score} sAudit3={school.audit_3_meet_standard}
                    max={school.max_score} sMax={school.standard_min}
                    link={`/${linkString}/${school.school_id.toString()}`}
                    provinceName={school.province_name}
                    regencyName={school.regency_name}
                    appType={appType}
                    stage={school.stage}
                    role={role}
                    setUpdate={setUpdate}
              // setUpdate={setUpdate} update={update}
            />
          </div>
        )
      }
    </div>
  );
}