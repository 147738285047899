import * as React from "react";
import axios from 'axios';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Autocomplete} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useEffect, useState} from "react";
import DialogX from "../../components/DialogX";

import {useForm} from "react-hook-form";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" "}
      <Link color="inherit" href="/signup">
        {process.env.REACT_APP_TITLE} - KemenPPPA
      </Link>
      {" - "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const [provinces, setProvinces] = useState([]);
  const [province, setProvince] = useState(); // for selected province
  const [regencies, setRegencies] = useState([{"label": ""}]);
  const [regency2, setRegency2] = useState("");
  const [schoolTypes, setSchoolTypes] = useState([{"label": ""}]);
  const [schoolType, setSchoolType] = useState("");

  //todo other data?

  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Sign up");

  const url = process.env.REACT_APP_SERVER;
  const appType = process.env.REACT_APP_APPTYPE;

  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // get provinces (only once)
  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER + "/provinces/v2")
      .then(response => {
        console.log(response.data.data);
        setProvinces(response.data.data);
      })
      .catch(err => {
        console.log(err);
      });
    console.log(provinces);
  }, []);

  // get regencies (each time change)
  useEffect(() => {
    if (province) {
      axios.get(process.env.REACT_APP_SERVER + "/regencies/v2?province=" + province.label)
        .then(response => {
          console.log(response.data.data);
          setRegencies(response.data.data);
          setRegency2({"label": ""});
        })
        .catch(err => {
          console.log(err);
        });
    }
    console.log("changing");
  }, [province]);

  // schooltypes
  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER + "/schooltypes/v2")
      .then(response => {
        setSchoolTypes(response.data.data);
        if (process.env.REACT_APP_APPTYPE === "PRAPMandiri") {
          setSchoolType({label: "Puskesmas", "id": 1});
        }
        ;
        console.log(response.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleSubmit3 = data => {
    // console.log("helo");
    // console.log(data);

    if (!regency2.id) {
      console.log("harus diisi regency");
      setDialogDescription("Provinsi dan Kab/Kota harus diisi");
      setOpen(true);
      return;
    } else {
      console.log(regency2.id);
    }

    if (!schoolType.id) {
      console.log("harus diisi schooltype");
      setDialogDescription("Tipe Satuan Pendidikan harus diisi");
      setOpen(true);
      return;
    } else {
      console.log(schoolType.id);
    }

    // console.log({
    //   email: data.email,
    //   password: data.password,
    //   name: data.name,
    //   schoolName: data.schoolName,
    //   regency: regency2.id,
    //   schoolType: schoolType.id,
    //   code: data.NPSN,
    //   address: data.alamat,
    //   whatsapp: data.whatsapp,
    // });
    axios.post(url + '/signup2', {
      email: data.email,
      password: data.password,
      name: data.name,
      schoolName: data.schoolName,
      regency: regency2.id,
      schoolType: schoolType.id,
      code: data.NPSN,
      address: data.alamat,
      whatsapp: data.whatsapp,
    })
      .then(response => {
        if (response.data.message === "email exist") {
          setDialogDescription("Email sudah ada di sistem, tidak dapat membuat akun baru");
          setOpen(true);
          return;
        } else {
          setDialogDescription("Silahkan check email anda, email konfirmasi sudah dikirimkan. " +
            "Jika tidak menerima email tersebut, silahkan cek di folder spam email anda.");
          setOpen(true);
        }
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  // const handleSubmit2 = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //     name: data.get("name"),
  //     schoolName: data.get("schoolName"),
  //     regency: regency2.id,
  //     schoolType: schoolType.id,
  //     whatsapp: data.get("whatsapp"),
  //     address: data.get("address")
  //   });
  //   axios.post(url + '/signup2', {
  //     email: data.get("email"),
  //     password: data.get("password"),
  //     name: data.get("name"),
  //     schoolName: data.get("schoolName"),
  //     regency: regency2.id,
  //     schoolType: schoolType.id
  //   })
  //     .then(response => {
  //       console.log(response);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  // https://mui.com/material-ui/react-autocomplete/
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {((appType === "SRA") || (appType === 'SRAMandiri')) &&
            <img src="./logo_sra_baru-nobg-120.png" alt="logo"/>
          }
          {((appType !== "SRA") && (appType !== 'SRAMandiri')) &&
            <img src="./76.png" alt="logo"/>
          }
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form
            component="form"
            noValidate
            onSubmit={handleSubmit(handleSubmit3)}
            sx={{mt: 3}}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nama Lengkap"
                  // focused={true}
                  autoFocus
                  {...register("name", {required: true})}
                />
                {errors.name && <div>nama harus diisi</div>}
              </Grid>
              {/*<Grid item xs={12} sm={6}>*/}
              {/*  <TextField*/}
              {/*    required*/}
              {/*    fullWidth*/}
              {/*    id="lastName"*/}
              {/*    label="Last Name"*/}
              {/*    name="lastName"*/}
              {/*    autoComplete="family-name"*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  {...register("email", {
                    required: "Email harus diisi",
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  })}
                />
                {/*  https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript */}
                {/*  {errors.email?.message + " - "}*/}
                {errors.email ? "Email harus diisi & harus menggunakan format email" : ""}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  {...register("password", {
                    required: "Password harus diisi",
                    pattern: /^[a-zA-Z0-9!@#$%^&*]{6,16}$/
                  })}
                />
                {errors.password ? "Password minimum 6 karakter, harus diisi, tidak boleh ada spasi" : ""}
                {/*  https://stackoverflow.com/questions/12090077/javascript-regular-expression-password-validation-having-special-characters */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="whatsapp"
                  label="Whatsapp"
                  name="whatsapp"
                  autoComplete="whatsapp"
                  {...register("whatsapp", {
                    pattern: /^\d*$/
                  })}
                />
                {errors.whatsapp ? "Whatsapp harus berisi angka saja" : ""}
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={*/}
              {/*      <Checkbox value="allowExtraEmails" color="primary" />*/}
              {/*    }*/}
              {/*    label="Apakah ingin menerima informasi dari kami seputar Satuan Pendidikan Ramah Anak."*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <TextField
                  autoComplete={process.env.REACT_APP_APPTYPE === 'SRAMandiri' ? "Nama Satuan Pendidikan" : "Nama Puskesmas"}
                  name="schoolName"
                  required
                  fullWidth
                  id="schoolName"
                  label={process.env.REACT_APP_APPTYPE === 'SRAMandiri' ? "Nama Satuan Pendidikan" : "Nama Puskesmas"}
                  // autoFocus
                  {...register("schoolName", {required: true})}
                />
                {errors.schoolName &&
                  <div>{process.env.REACT_APP_APPTYPE === 'SRAMandiri' ? "Nama Satuan Pendidikan" : "Nama Puskesmas"} harus
                    diisi</div>}
              </Grid>
              {(process.env.REACT_APP_APPTYPE === 'SRAMandiri') &&
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="NPSN"
                    label="NPSN"
                    name="NPSN"
                    autoComplete="NPSN"
                    {...register("NPSN", {
                      required: true,
                      pattern: /^\d{8}$/
                    })}
                  />
                  {errors.NPSN ? "Format NPSN salah dan harus diisi" : ""}
                </Grid>
              }
              {(process.env.REACT_APP_APPTYPE === 'PRAPMandiri') &&
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="NPSN"
                    label="Kode Puskesmas"
                    name="NPSN"
                    autoComplete="NPSN"
                    {...register("NPSN", {
                      required: true,
                      pattern: /^P\d{10}$/
                    })}
                  />
                  {errors.NPSN ? "Format Kode Puskesmas salah (huruf awal = P) dan harus diisi " : ""}
                </Grid>
              }
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="province2"
                  value={province}
                  options={provinces}
                  // sx={{width: 300}}
                  onChange={(event, newValue) => {
                    setProvince(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Provinsi"/>}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="regency"
                  // name="Kab/Kota"
                  value={regency2}
                  options={regencies}
                  // sx={{width: 300}}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setRegency2(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Kab/Kota"/>}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="alamat"
                  label="Alamat"
                  multiline={true}
                  rows={4}
                  required={true}
                  name="Alamat"
                  autoComplete="alamat"
                  {...register("alamat", {
                    required: true,
                  })}
                />
                {errors.alamat ? "Alamat harus diisi" : ""}
              </Grid>
              {
                (process.env.REACT_APP_APPTYPE === 'SRAMandiri') &&
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="schooltype"
                    value={schoolType}
                    options={schoolTypes}
                    // sx={{width: 300}}
                    onChange={(event, newValue) => {
                      console.log("new:", newValue);
                      setSchoolType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params}
                                                        label={process.env.REACT_APP_APPTYPE === "SRAMandiri" ? "Tipe Satuan Pendidikan" : "Tipe Puskesmas"}/>}
                  />
                </Grid>
              }
              {/*<Grid item xs={12}>*/}
              {/*  {regency2.label}*/}
              {/*</Grid>*/}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Apakah anda sudah memiliki akun? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
        <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
        <Copyright sx={{mt: 5}}/>
      </Container>
    </ThemeProvider>
  );
}
