import React, {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Box} from "@mui/material";

export default function DualList3() {
  const [leftItems, setLeftItems] = useState([
    // {userid: 1, email:'Item 01'},
    // {userid: 2, email:'Item 02'},
    // {userid: 3, email:'Item 03'},
    // {userid: 4, email:'Item 04'},
    'Item 05',
    'Item 06',
    'Item 07',
    'Item 08',
    'Item 09',
    'Item 10',
  ]);
  const [rightItems, setRightItems] = useState([
    // {userid: 14, email:'Item 14'},
    // {userid: 12, email:'Item 12'},
    'Item 11',
    'Item 13',
  ]);
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);
  const [leftFilter, setLeftFilter] = useState('');
  const [rightFilter, setRightFilter] = useState('');

  const handleLeftToggle = (value) => () => {
    const currentIndex = leftChecked.indexOf(value);
    const newChecked = [...leftChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setLeftChecked(newChecked);
  };

  const handleRightToggle = (value) => () => {
    const currentIndex = rightChecked.indexOf(value);
    const newChecked = [...rightChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRightChecked(newChecked);
  };

  const handleMoveRight = () => {
    const newLeftItems = [...leftItems];
    const newRightItems = [...rightItems];

    leftChecked.forEach((value) => {
      const index = newLeftItems.indexOf(value);
      if (index !== -1) {
        newLeftItems.splice(index, 1);
        newRightItems.push(value);
      }
    });

    newRightItems.sort();
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
    setLeftChecked([]);
  };

  const handleMoveLeft = () => {
    const newLeftItems = [...leftItems];
    const newRightItems = [...rightItems];

    rightChecked.forEach((value) => {
      const index = newRightItems.indexOf(value);
      if (index !== -1) {
        newRightItems.splice(index, 1);
        newLeftItems.push(value);
      }
    });

    newLeftItems.sort();
    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
    setRightChecked([]);
  };

  const handleLeftFilterChange = (event) => {
    setLeftFilter(event.target.value);
  };

  const handleRightFilterChange = (event) => {
    setRightFilter(event.target.value);
  };

  const leftFilteredItems = leftItems.filter((item) =>
    item.toLowerCase().includes(leftFilter.toLowerCase())
  );

  const rightFilteredItems = rightItems.filter((item) =>
    item.toLowerCase().includes(rightFilter.toLowerCase())
  );

  const onSubmit = () => {
    console.log('leftItems', leftItems);
    console.log('rightItems', rightItems);
  };


  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px'}}>
          <TextField
            label="Filter Items"
            value={leftFilter}
            onChange={handleLeftFilterChange}
            variant="outlined"
            margin="dense"
            style={{marginBottom: '20px', width: '100%'}}
          />
          <List
            sx={{
              width: '100%',
              minHeight: '400px',
              maxHeight: '400px',
              overflowY: 'auto',
              bgcolor: 'background.paper',
            }}
          >
            {leftFilteredItems.map((value) => {
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton role={undefined} onClick={handleLeftToggle(value)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={leftChecked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value}/>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
        <div>
          <IconButton aria-label="move right" onClick={handleMoveRight}>
            <ArrowRightIcon/>
          </IconButton>
          <IconButton aria-label="move left" onClick={handleMoveLeft}>
            <ArrowLeftIcon/>
          </IconButton>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px'}}>
          <TextField
            label="Filter Items"
            value={rightFilter}
            onChange={handleRightFilterChange}
            variant="outlined"
            margin="dense"
            style={{marginBottom: '20px', width: '100%'}}
          />
          <List
            sx={{
              width: '100%',
              minHeight: '400px',
              maxHeight: '400px',
              overflowY: 'auto',
              bgcolor: 'background.paper',
            }}
          >
            {rightFilteredItems.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton role={undefined} onClick={handleRightToggle(value)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={rightChecked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value}/>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Button onClick={onSubmit} variant="contained">
          Submit
        </Button>
      </Box>
    </div>
  );
}