import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {useForm} from "react-hook-form";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" "}
      <Link color="inherit" href="#">
        {process.env.REACT_APP_TITLE} - KemenPPPA
      </Link>
      {" - "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgetPassword() {
  const appType = process.env.REACT_APP_APPTYPE;
  const url = process.env.REACT_APP_SERVER;
  const {register, handleSubmit, watch, formState: {errors}} = useForm();

  const handleSubmit3 = data => {
    // console.log("helo");
    // console.log(data);
    axios.post(url + "/forgetpassword", data)
      .then(res => {
        // console.log(res.data.message);
        if (res.data.message === 'forget password, check email') {
          alert("Email konfirmasi telah dikirim ke email anda. Silahkan cek email anda. Periksa juga folder spam jika tidak ada di inbox. Konfirmasi email hanya berlaku 1 jam dari sekarang. Terima kasih.");
        } else {
          alert("Email tidak ditemukan. Silahkan cek kembali pengisian field email anda.");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        {/*<form*/}
        {/*  component="form"*/}
        {/*  noValidate*/}
        {/*  onSubmit={handleSubmit(handleSubmit3)}*/}
        {/*  sx={{mt: 3}}*/}
        {/*>*/}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {((appType === "SRA") || (appType === 'SRAMandiri')) &&
            <img src="/logo_sra_baru-nobg-120.png" alt="logo"/>
          }
          {((appType !== "SRA") && (appType !== 'SRAMandiri')) &&
            <img src="/76.png" alt="logo"/>
          }
          {/*<Avatar sx={{m: 1, bgcolor: "secondary.main"}}>*/}
          <br/>
          <Typography component="h1" variant="h5">
            Lupa Password
          </Typography>

          <form
            noValidate
            onSubmit={handleSubmit(handleSubmit3)}
          >
            <Box sx={{mt: 1}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                {...register("email", {
                  required: "Email harus diisi",
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
              />
              {errors.email ? "Email harus diisi & harus menggunakan format email" : ""}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
              >
                Submit
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/signin" variant="body2">
                    Apakah anda sudah memiliki akun? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}}/>
        {/*</form>*/}
      </Container>
    </ThemeProvider>
  );
}