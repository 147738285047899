import {BrowserRouter, Route, Switch, useHistory, useLocation} from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Em1 from "./pages/Em1/Em1";
import SignIn from "./pages/Signup/SignIn";
import SignUp from "./pages/Signup/SignUp";
import Confirmation from "./pages/Signup/Confirmation";
// import {ResetPassword} from "./pages/Signup/ResetPassword";

// import SchoolCategories from "./pages/SchoolCategories/SchoolCategories";
// import SchoolQuestions from "./pages/SchoolQuestions/SchoolQuestions";

// import useToken from "./useToken";
import useUser from "./user/use";

// import UserProvider from "./user/provider";
// import useUser from "./user/use";
import "./App.css";
import MyDialog from "./pages/Development/MyDialog";
import MyDialogY from "./pages/Development/MyDialogY";
import MyTable from "./pages/Development/MyTable";
import MyTable2 from "./pages/Development/MyTable2";
import DualList from "./pages/Development/DualList";
import DualList2 from "./pages/Development/DualList2";
import DualList3 from "./pages/Development/DualList3";

import Admin from "./pages/Admin/Admin";
import React, {useEffect} from "react";
import axios from "axios";

import RequireAuth from "./components/RequireAuth";
import SchoolsManagement from "./pages/Admin/SchoolsManagement/SchoolsManagement";
import UsersManagement from "./pages/Admin/UsersManagement/UsersManagement";
import SchoolQuestions2 from "./pages/SchoolQuestions/SchoolQuestions2";
import SchoolCategories2 from "./pages/SchoolCategories/SchoolCategories2";
import SchoolsList from "./pages/SchoolsList/SchoolsList";
// import UserIdCheck from "./components/UserIdCheck";
// import Files from "./pages/Files/Files"


import PostList from "./pages/Post/PostList";
import PostDetail from "./pages/Post/PostDetail";

import Contact from "./pages/Contact/Contact";

// import {Em1Attachment} from "./pages/EM1Attachment/EM1Attachment";

import {ThemeProvider, createTheme} from '@mui/material/styles';
import ChangePassword from "./pages/Signup/ChangePassword";
import candles from "./pages/Signup/pisa-back-1.jpeg";
import Tutorial from "./pages/Tutorial/Tutorial";
import {Province} from "./pages/Province/Province";
import {Regency} from "./pages/Regency/Regency";
import Nation from "./pages/Nation/Nation";
import Province2 from "./pages/Province2/Province2";

import ForgetPassword from "./pages/Signup/ForgetPassword";
import ForgetNewPassword from "./pages/Signup/ForgetNewPassword";
import ResetConfirmation from "./pages/Signup/ResetConfirmation";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Source Sans Pro',
      `sans-serif`,
    ].join(','),
  },
  button: {
    borderRadius: '0px',
    boxShadow: 'none'
  }
});

function App() {
  // const [school_id, setSchool_id] = useState("1");
  // const [role, setRole] = useState("");
  // const [user_id, setUser_id] = useState("1");
  // const {token, setToken} = useToken();
  const {
    state: {role, token, userId},
    actions: {setRole, setToken, setUserId}
  } = useUser();

  const history = useHistory();
  const location = useLocation(); // https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route

  const appType = process.env.REACT_APP_APPTYPE;

  const signOut = () => {
    setUserId(0);
    // setEmail("");
    setRole("");
    // setSchoolId(null);
    setToken({token: ""});
  };

  // check changing userid
  useEffect(() => {
    if (token) {
      const checkUserId = () => {
        const query2 = process.env.REACT_APP_SERVER + "/anyuser";
        axios.get(query2, {params: {secret_token: token, checkUserId: userId}})
          .then(response => {
              // console.log("response", response.data);
              // console.log("response.data.message", response.data.message);
              if (response.data.message === "userId different") {
                // console.log("User/Pass Wrong");
                // setDialogDescription(`Anda tidak mempunyai akses ${role}`);
                // setOpen(true);
                // setToken("");
                history.push("/");
                signOut();
              } else {
                // setToken({token: tempToken});
                // setUserId(tempUserId); // get userId from response
              }
            }
          )
          .catch(err => {
            console.log(err);
          });
      };
      checkUserId();
    }
  }, [history, signOut, token, userId]);

  // console.log(location.pathname);
  // console.log("token1:", token);
  if ((!token) && (!(location.pathname === "/signup") && !(location.pathname === "/confirmation")
    && !(location.pathname === "/forgetpassword") && !(location.pathname === "/forgetnewpassword")
    && !(location.pathname === "/resetconfirmation")
  )) {
    return <SignIn/>;
  }
  // console.log("token2:", token);

  if ((location.pathname === "/signup")) {
    return <SignUp/>;
  }

  if ((location.pathname === "/signout")) {
    history.push("/");
    signOut();
    return;
  }

  if ((location.pathname === "/confirmation")) {
    return <Confirmation/>;
  }

  if ((location.pathname === "/forgetpassword")) {
    return <ForgetPassword/>;
  }

  if ((location.pathname === "/resetconfirmation")) {
    return <ResetConfirmation/>;
  }

  if ((location.pathname === "/forgetnewpassword")) {
    return <ForgetNewPassword/>;
  }

  const localStyle = (appType === "PISA") ? "App" : "App"; // todo: temporary using App for PISA

  return (
    // <UserProvider>
    <ThemeProvider theme={theme}>
      <div className={localStyle}>
        <BrowserRouter>
          {(!(location.pathname === "/signup")) &&
            <Navbar role={role} signOut={signOut}/>
          }
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            {/*<Route path="/schoolcategories/:schoolid">*/}
            {/*  <SchoolCategories/>*/}
            {/*</Route>*/}
            <Route path="/schoolslist">
              <SchoolsList/>
            </Route>
            {/* alternative */}
            <Route path="/schoolcategories2/:schoolid">
              <SchoolCategories2/>
            </Route>
            {/*<Route path="/schoolquestions/:id/:schoolid">*/}
            {/*  <SchoolQuestions/>*/}
            {/*</Route>*/}
            {/* alternative */}
            <Route path="/schoolquestions2/:categoryid/:schoolid">
              <SchoolQuestions2/>
            </Route>
            <Route path="/em1/:formid/:schoolid">
              <Em1/>
            </Route>
            <Route path="/signin">
              <SignIn/>
            </Route>
            <Route path="/signup">
              <SignUp/>
            </Route>
            <Route path="/confirmation">
              <Confirmation/>
            </Route>
            {/*<Route path="/resetpassword">*/}
            {/*  <ResetPassword/>*/}
            {/*</Route>*/}
            <Route path="/changepassword">
              <ChangePassword/>
            </Route>
            <Route path="/admin">
              <RequireAuth allowedRoles={["administrator"]}>
                <Admin/>
              </RequireAuth>
            </Route>
            <Route path="/usersmanagement">
              <RequireAuth allowedRoles={["administrator"]}>
                <UsersManagement/>
              </RequireAuth>
            </Route>
            <Route path="/schoolsmanagement">
              <RequireAuth allowedRoles={["administrator"]}>
                <SchoolsManagement/>
              </RequireAuth>
            </Route>
            <Route path="/postlist">
              <PostList/>
            </Route>
            <Route path="/postdetail/:postid">
              <PostDetail/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/tutorial">
              <Tutorial/>
            </Route>
            <Route path="/province2/:provinceId">
              <Province2/>
            </Route>
            <Route path="/province">
              <Province/>
            </Route>
            <Route path="/regency/:regencyId">
              <Regency/>
            </Route>
            <Route path="/nation">
              <RequireAuth allowedRoles={["viewerpusat", "administrator"]}>
                <Nation/>
              </RequireAuth>
            </Route>
            <Route path="/forgetpassword">
              <ForgetPassword/>
            </Route>
            <Route path="/forgetnewpassword">
              <ForgetNewPassword/>
            </Route>
            <Route path={'/resetconfirmation'}>
              <ResetConfirmation/>
            </Route>

            {/*-------------------------------------------------------------------------------------*/}
            {/* below for testing only */}
            {/*<Route path="/files">*/}
            {/*  <Files/>*/}
            {/*</Route>*/}
            <Route path="/mydialog">
              <RequireAuth allowedRoles={["administrator"]}>
                <MyDialog/>
              </RequireAuth>
            </Route>
            <Route path="/mydialogy">
              <RequireAuth allowedRoles={["administrator"]}>
                <MyDialogY/>
              </RequireAuth>
            </Route>
            <Route path="/mytable">
              <RequireAuth allowedRoles={["administrator"]}>
                <MyTable/>
              </RequireAuth>
            </Route>
            <Route path="/mytable2">
              <RequireAuth allowedRoles={["administrator"]}>
                <MyTable2/>
              </RequireAuth>
            </Route>
            <Route path="/duallist">
              <RequireAuth allowedRoles={["administrator"]}>
                <DualList/>
              </RequireAuth>
            </Route>
            <Route path="/duallist2">
              <RequireAuth allowedRoles={["administrator"]}>
                <DualList2/>
              </RequireAuth>
            </Route>
            <Route path="/duallist3">
              <RequireAuth allowedRoles={["administrator"]}>
                <DualList3/>
              </RequireAuth>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
    // </UserProvider>
  );
}

export default App;