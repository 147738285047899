import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function DualList() {
  const [leftItems, setLeftItems] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4']);
  const [rightItems, setRightItems] = useState(['Item 5', 'Item 6', 'Item 7', 'Item 8']);
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);

  const handleLeftToggle = (value) => () => {
    const currentIndex = leftChecked.indexOf(value);
    const newChecked = [...leftChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setLeftChecked(newChecked);
  };

  const handleRightToggle = (value) => () => {
    const currentIndex = rightChecked.indexOf(value);
    const newChecked = [...rightChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRightChecked(newChecked);
  };

  const handleMoveRight = () => {
    const newLeftItems = [...leftItems];
    const newRightItems = [...rightItems];

    leftChecked.forEach((value) => {
      const index = newLeftItems.indexOf(value);
      if (index !== -1) {
        newLeftItems.splice(index, 1);
        newRightItems.push(value);
      }
    });

    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
    setLeftChecked([]);
  };

  const handleMoveLeft = () => {
    const newLeftItems = [...leftItems];
    const newRightItems = [...rightItems];

    rightChecked.forEach((value) => {
      const index = newRightItems.indexOf(value);
      if (index !== -1) {
        newRightItems.splice(index, 1);
        newLeftItems.push(value);
      }
    });

    setLeftItems(newLeftItems);
    setRightItems(newRightItems);
    setRightChecked([]);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <List sx={{ width: '40%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {leftItems.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value} disablePadding>
              <ListItemButton role={undefined} onClick={handleLeftToggle(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={leftChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 16px' }}>
        <IconButton onClick={handleMoveRight} disabled={leftChecked.length === 0}>
          <ArrowRightIcon />
        </IconButton>
        <IconButton onClick={handleMoveLeft} disabled={rightChecked.length === 0}>
          <ArrowLeftIcon />
        </IconButton>
      </div>

      <List sx={{ width: '40%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {rightItems.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={value} disablePadding>
              <ListItemButton role={undefined} onClick={handleRightToggle(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={rightChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}