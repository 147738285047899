import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {Box, CircularProgress, Typography} from "@mui/material";
import Button from "@mui/material/Button";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  return date.toLocaleDateString('id-ID', options);
}

function PostDetail(props) {
  const [content, setContent] = useState("");
  const {postid} = useParams();  // postId is from the path="/PostDetail/:postId"

  useEffect(() => {
    const query = process.env.REACT_APP_CONTENT + `/posts/${postid}`;
    axios.get(query)
      .then(response => {
        setContent(response.data);
        // console.log(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [postid]);

  return (
    <div>
      <Box marginTop={4}>
        {/*<Grid container>*/}
        {/*  <Grid item={true} xs={2}> </Grid>*/}
        {/*  <Link to={"/PostList"} style={{textDecoration: 'none'}}>*/}
        {/*    <Grid item={true} xs={8}>*/}
        {/*      <Typography variant="body1" component="p" gutterBottom={true}>*/}
        {/*      Kembali ke Daftar*/}
        {/*      </Typography>*/}
        {/*    </Grid>*/}
        {/*  </Link>*/}
        {/*  <Grid item={true} xs={2}> </Grid>*/}
        {/*</Grid>*/}
        <Grid container>
          <Grid item xs={2}/>
          <Grid item xs={8}>
            <Link to="/PostList" style={{textDecoration: 'none'}}>
              <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}>
                Kembali
              </Button>
            </Link>
          </Grid>
          <Grid item xs={2}/>
        </Grid>
        {!content &&
          <>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}> </Grid>
              <Grid item={true} xs={2}> </Grid>
              <Grid item={true} xs={8}>
                <CircularProgress/>
              </Grid>
              <Grid item={true} xs={2}> </Grid>
            </Grid>
          </>
        }
        {content &&
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
            </Grid>
            <Grid item={true} xs={2}> </Grid>
            <Grid item={true} xs={8}>
              <Typography variant="h4" component="h1" mt="0px" mb="0px" pt="0px" pb="0px" gutterBottom={true}>
                {content.title.rendered}
              </Typography>
            </Grid>
            <Grid item={true} xs={2}> </Grid>

            <Grid item={true} xs={2}> </Grid>
            <Grid item={true} xs={8}>
              <Typography variant="body1" component="p" mt="0px" mb="0px" pt="0px" pb="0px" gutterBottom={true}>
                {formatDate(content.date)}
              </Typography>
            </Grid>
            <Grid item={true} xs={2}> </Grid>

            <Grid item={true} xs={2}> </Grid>
            <Grid item={true} xs={8}>
              {/*<Typography variant="body1" component="p"  gutterBottom={true}>*/}
              <div dangerouslySetInnerHTML={{__html: content.content.rendered}}/>
              {/*</Typography>*/}
            </Grid>
            <Grid item={true} xs={2}> </Grid>
          </Grid>
        }
      </Box>
    </div>
  )
    ;
}

export default PostDetail;