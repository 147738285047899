import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import useUser from "../../user/use";
import "./Home.css";
import axios from "axios";

import {Box, Card, CardContent, Typography, Stack} from "@mui/material";

const InfoSchoolValue = ({schoolId, role}) => {
  const [schoolValue, setSchoolValue] = useState(null);
  const [schoolValueAudit1, setSchoolVaueAudit1] = useState((null));

  useEffect(() => {
    // const schoolId = schoolId;
    try {
      async function fetchData() {
        const queryString = process.env.REACT_APP_SERVER + "/schoolvalue";
        const data = await axios.get(queryString, {params: {schoolId: schoolId}});
        setSchoolValue(data.data.data[0]);
        setSchoolVaueAudit1(data.data.dataAudit1[0]);
        // console.log("dataAudit1", data.data.dataAudit1[0]);
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [schoolId]);

  return (
    <div>
      {schoolValue &&
        <div>
          <Box paddingLeft={2} paddingTop={2} paddingRight={2} paddingBottom={2}>
            <Typography variant="body1" color="text.secondary">Total Nilai EM1: {schoolValue.totalEm1Score}</Typography>
            {role === "auditor" &&
              <div>
                <Typography variant="body1" color="text.secondary">Total Nilai
                  Audit1: {schoolValueAudit1.totalAudit1Score}</Typography>
              </div>
            }
            <Typography variant="body1" color="text.secondary">Total Nilai Max: {schoolValue.maxScore}</Typography>
            <Typography variant="body1" color="text.secondary">Sudah Memenuhi Standard
              Minimum: {schoolValue.minStandardCount}</Typography>
            <Typography variant="body1" color="text.secondary">Total Sudah Diisi: {schoolValue.filledCount}</Typography>
            <Typography variant="body1" color="text.secondary">Total
              Pertanyaan: {schoolValue.totalQuestions}</Typography>
          </Box>
        </div>
      }
    </div>
  );
};

const listSchools = async (userId, role) => {
  try {
    const queryString = process.env.REACT_APP_SERVER + "/schools";
    const data = await axios.get(queryString, {params: {userId: userId, role: role}});
    // console.log("data:", data);
    return data;
  } catch (err) {
    console.log(err);
  }
};

const RoleView = ({userId, role}) => {
  const [data, setData] = useState();
  const history = useHistory();
  const [titleInstrument, setTitleInstrument] = useState("");
  const [linkString, setLinkString] = useState("");

  // let titleInstrument = "";
  // let linkString = "";

  useEffect(() => {
    switch (role) {
      case "operator":
        setTitleInstrument("Peserta");
        // titleInstrument = "Peserta";
        setLinkString("schoolcategories2");
        // linkString = "schoolcategories2";
        history.push("/schoolslist");
        break;
      case "auditor":
        setTitleInstrument("Auditor");
        // titleInstrument = "Auditor";
        setLinkString("schoolcategories2");
        // linkString = "schoolcategories2";
        history.push("/schoolslist");
        break;

      case "province":
        setTitleInstrument("Province");
        // titleInstrument = "Province";
        setLinkString("schoolcategories2");
        // linkString = "schoolcategories2";
        // redirect to new home page
        // TODO: is this the best way?
        history.push("/province");
        // history.push("/schoolslist");
        break;

      case "viewerpusat":
        setTitleInstrument("Pusat");
        // titleInstrument = "Pusat";
        setLinkString("schoolcategories2");
        // linkString = "schoolcategories2";
        // redirect to new home page
        // TODO: is this the best way?
        // history.push("/schoolslist");
        history.push("/nation");

        break;
      case "administrator":
        setTitleInstrument("Administrator");
        // titleInstrument = "Administrator";
        setLinkString("schoolcategories2");
        // linkString = "schoolcategories2";
        history.push("/schoolslist");
        break;
    }
  }, [role]);

  useEffect(() => {
    listSchools(userId, role)
      .then(data => {
        setData(data.data);
      })
      .catch(err => console.log(err));
  }, [role, userId]);

  return (
    <div className="home">
      <Typography variant="h5">BERANDA</Typography>
      <Typography variant="h6">{titleInstrument}</Typography>
      {/*<Link to="/schoolcategories">Peserta</Link>*/}
      <div>
        <Stack direction="row" sx={{
          flexWrap: "wrap"
        }}>
          {data && data.data.map((singleData) => {
            return (
              <div key={singleData.id}>
                <Card sx={{
                  width: "350px",
                  marginTop: 2,
                  marginLeft: 2,
                  marginBottom: 2,
                  marginRight: 2,
                }}>
                  <CardContent sx={{
                    height: 80,
                    borderBottom: "1px solid #d2d7da",
                    backgroundColor: "#8FC7C7"
                  }}>
                    <Typography variant="h5">
                      <Link to={`/${linkString}/${singleData.school_id.toString()}`} style={{textDecoration: 'none'}}>
                        <Typography variant="h5">{singleData.school_name}</Typography>
                      </Link>
                    </Typography>
                  </CardContent>
                  <InfoSchoolValue schoolId={singleData.school_id} role={role}>
                  </InfoSchoolValue>
                  {/*<p>{singleData.school_id}</p>*/}
                </Card>
              </div>);
          })}
        </Stack>
      </div>
    </div>
  );
};

export default function Home() {
  const {
    state: {userId, role, token},
  } = useUser();

  return (
    <div>
      {(process.env.REACT_APP_DEV == 1) &&
        <div>
          schoolid:- - stage:- - userid:{userId} - role:{role}
        </div>
      }
      <RoleView userId={userId} role={role}>
      </RoleView>
    </div>
  );
}
