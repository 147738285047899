import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import "./index.css";
import App from "./App";
import DynamicFavicon from './DynamicFavicon';

import UserProvider from "./user/provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <DynamicFavicon icon={process.env.REACT_APP_FAVICON} />
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App/>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </UserProvider>
);

// Remove React.StrictMode for compatible with React-Router-Dom 5
